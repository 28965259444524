import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ShopLink from '../../images/shop-lg-button.png'
import ClickedShopLink from '../../images/shop-lg-button-pushed.png'
import ShowsLink from '../../images/shows-lg-button.png'
import ClickedShowsLink from '../../images/shows-lg-button-pushed.png'
import ContactLink from '../../images/contact-lg-button.png'
import ClickedContactLink from '../../images/contact-lg-button-pushed.png'
import Logo from '../../images/mictv-logo.png'

class Header extends Component {

    linkClicked(id) {
        // console.log(`link id (${id}) CLICKED!`);
        const link = document.getElementById(id);
        // console.log(link.src);
        switch(id) {
            case 'showsLink':
                link.src = ClickedShowsLink;
                break;
            case 'shopLink':
                link.src = ClickedShopLink;
                break;
            case 'contactLink':
                link.src = ClickedContactLink;
                break;
            default:
                console.log('ID failed to match an element (link click)')
        }
    }

    linkReleased(id) {
        // console.log(`link id (${id}) RELEASED!`);
        const link = document.getElementById(id);
        switch(id) {
            case 'showsLink':
                link.src = ShowsLink;
                break;
            case 'shopLink':
                link.src = ShopLink;
                break;
            case 'contactLink':
                link.src = ContactLink;
                break;
            default:
                console.log('ID failed to match an element (link click)')
        }
    }

    render() {
        return (
            <div className='Header col-sm-10 col-md-8 col-lg-6' >
                <Link to='/'><img src={ Logo } className='logoStyle' alt="MicTV Logo" /></Link>
                <nav className='navbarStyle'>
                    <Link to={`/shop`}>
                        <img id='shopLink' src={ ShopLink } className='linkStyle' alt='shop - navigation link' 
                            onMouseDown={ () => this.linkClicked('shopLink') }
                            onMouseUp={ () => this.linkReleased('shopLink') }    
                        ></img>
                    </Link>
                    <Link to={`/shows`}>
                        <img id='showsLink' src={ ShowsLink } className='linkStyle' alt='shows - navigation link'
                            onMouseDown={ () => this.linkClicked('showsLink') }
                            onMouseUp={ () => this.linkReleased('showsLink') }>
                        </img>
                    </Link>
                    <Link to={`/contact`}>
                        <img id='contactLink' src={ ContactLink } className='linkStyle' alt='contact - navigation link'
                            onMouseDown={ () => this.linkClicked('contactLink') }
                            onMouseUp={ () => this.linkReleased('contactLink') }>
                        </img>
                    </Link>
                </nav>
            </div>
        )
    }
}
 
export default Header;

