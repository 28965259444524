import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import Amplify from 'aws-sdk';
// import config from './config.js';

// Amplify.configure({
//     Storage: {
//       region: config.s3.REGION,
//       bucket: config.s3.BUCKET,
//       identityPoolId: config.cognito.IDENTITY_POOL_ID
//     },
//     API: {
//       endpoints: [
//         {
//           name: "notes",
//           endpoint: config.apiGateway.URL,
//           region: config.apiGateway.REGION
//         },
//       ]
//     }
//   });

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
