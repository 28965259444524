import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// Pages & layouts
import Shop from './components/pages/Shop';
import Shows from './components/pages/Shows'
import Contact from './components/pages/Contact'
import About from './components/pages/About'
import Thanks from './components/pages/Thanks'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import TVSet from './components/layout/TVSet'
import Error404 from './components/pages/Error'
import './App.css'
import './styles/css/styles.css'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentBgColor: 'bgPink',
      bgPink: 'rgba(224, 172, 155, 0.25)',
      bgGreen: 'rgba(117, 216, 102, 0.32)'
    }
  }

  changeBgColor(nextColor) {
    // console.log(`Changing to ${nextColor} now!`)
    document.body.style.background = this.state[nextColor];
  }

  clickTV(currColor) {
    // console.log(`Click in TV set!\ncurrColor: ${currColor}`)
    if(currColor === 'bgPink') {
      this.changeBgColor('bgGreen');
      this.setState({
        currentBgColor: 'bgGreen'
      })
    } else {
      this.changeBgColor('bgPink');
      this.setState({
        currentBgColor: 'bgPink'
      })
    }
  }


  render() {
    // console.log(this.state.shows);
    const currentBgColor = this.state.currentBgColor;
    return (
      <div className="App">
        <div className="container">
          <Header />
            <Switch>
              <Route exact
                path='/'
                render={ () => <TVSet bgColor={ this.state.currentBgColor } clickTV={ () => this.clickTV(currentBgColor) } /> } />
              <Route path='/shop' component={ Shop } />
              <Route path='/shows' component={ Shows } />
              <Route path='/contact' component={ Contact } />
              <Route path='/about' component={ About } />
              <Route path='/thanks' component={ Thanks } />
              <Route component={ Error404 } />
            </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
