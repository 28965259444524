import React from 'react'
import ShopImg from '../../images/shop.png'
import ShootOutImage from '../../images/shoot-out.png'
import Line from '../layout/Line'

function Shop() {

    return ( 
        <div className='Shop' >
            <img id='shopLogo' className='title' style={{ margin: 'auto' }} src={ ShopImg } alt='Shop Page for MicTV' />
            <Line />
            <img className='shootOut' style={{ margin: 'auto' }} src={ ShootOutImage } alt='Artwork by John MacIntire' />
            <Line />
        </div>
    );
}
 
export default Shop;
