import React from 'react'
import Signature from '../../images/designed-by.png'

export default function Footer() {
    return (
        <div className='Footer' >
            <a href='https://johnmacintire.com'><img src={ Signature } alt="Designed by John MacIntire" /></a>
        </div>
    )
}
