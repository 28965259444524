import React from 'react'
import Line from '../layout/Line'

function Error404() {

    return ( 
        <div className='Error' >
            <Line />
            <h3>Error 404 - Page Not Found!</h3>
            <Line />
        </div>
    );
}
 
export default Error404;