import React, { Component } from 'react';
import ShowImg from '../../images/shows.png'
import ShowObject from '../layout/ShowObject'
import ShowData from '../../data/shows.json'
import Line from '../layout/Line'
import uuid from 'uuid'

class Shows extends Component {

    constructor(props) {    
        super(props);
        this.state = {
            shows: []
        }
    }

    componentDidMount() {
        this.setState({
            shows: [...ShowData]
        });
    }

    render() {
        return (
            <div className='Shows'>
                <img id='showsLogo' className='title' src={ ShowImg } alt='Shows Page for MicTV' />
                <Line />
                {/* { 
                    this.state.shows.map( show => {
                        return (
                            <ShowObject
                                show={ show }
                                key={ uuid.v4() }  
                            />
                        )
                    })
                } */}
                <h4 className="showsPlaceholderText">Polishing up a couple more projects...</h4>
                <Line />
            </div>
        )
    }
}

export default Shows;
