import React, { Component } from 'react';
import TV from '../../images/tv-base.png'
import TVLizard from '../../images/tv-lizard.png'

class TVSet extends Component {

    render() {
        return (
            <div className='TVSet'>
                <img src={ TVLizard } alt="MicTV TV set" onClick={ () => this.props.clickTV() } ></img>
            </div>
        )
    }
}

export default TVSet;