import React from 'react';
import { Component } from 'react';
import ContactImg from '../../images/contact.png'
import Line from '../layout/Line'
import convertFormElements from '../../scripts/formToJson'

class Contact extends Component {

    postForm = async (formJson, url) => {
        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'default',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: formJson
        }).then(response => response.json())
        .then(obj => {
            const redirectUrl = obj.errorType; // masked as Error for transfer through API Gateway
            console.log('Redirect URL from JSON form submission response:\n', redirectUrl)
            this.props.history.push('/thanks')
        });
    }

    submitForm = async (event) => {
        event.preventDefault();
        console.log(`Inside 'submitForm'!`);
        const form = document.getElementsByClassName('contact-form')[0];
        if(process.env.NODE_ENV !== 'test') event.preventDefault();
        if(form) {
            // form.addEventListener('submit', this.submitForm); ~ Removing because React already has a listener
            const formData = convertFormElements(form.elements);
            // console.log('data:\n', JSON.stringify(formData));
            const apiUrl ='https://i4mh9qpxk9.execute-api.us-west-2.amazonaws.com/dev/contact';
            this.postForm(JSON.stringify(formData), apiUrl);
            return formData;
        } else {
            console.error(`FORM ERROR: Can't find 'form' element.`, form);
            return { "error": "FORM ERROR: Can't find 'form' element." };
        }
    }

    render() {
        return (
            <div className='Contact' >
                <img className='title' src={ ContactImg } alt='Contact Page for MicTV' />
                <Line />
                <div className='ContactForm container'>
                    <form className='contact-form' method='POST'>
                        <div className='formGroup row'>
                            <label htmlFor='name'><b>Name:</b> </label>
                            <div>
                                <input type='text' name='name' className='form-control ContactFormInput' placeholder='Name' required></input>
                            </div>
                        </div>
                        <div className='formGroup row'>
                            <label htmlFor='email'><b>Email:</b> </label>
                            <div>
                                <input type='text' name='email' className='form-control ContactFormInput' placeholder='Email Address' required></input>
                            </div>
                        </div>
                        <div className='formGroup row'>
                            <label htmlFor='message'><b>Message:</b> </label>
                            <div>
                                <input type='text' name='message' className='form-control ContactFormInput' placeholder='Message here...'></input>
                            </div>
                        </div>
                        <div className='formGroup row'>
                            <button id='submit' type='submit' className='btn btn-primary submitButton' onClick={ this.submitForm } >Submit</button>
                        </div>
                    </form>
                </div>
                <Line />
            </div>
        );
    }
}

export default Contact;